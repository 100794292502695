import DatePicker from "react-datepicker";
import {FieldHookConfig, useField} from "formik";
import 'react-datepicker/dist/react-datepicker.css'
import {InputProps} from "../../../models/Inputs/InputProps";

const DateInput = (props: Partial<InputProps> & FieldHookConfig<string>) => {
    const [field, meta, helpers] = useField(props.name);

    const {value} = meta;
    const {setValue} = helpers;

    const handleSetValue = (date: Date | null) => {
        setValue(date);
    };

    return (
        <DatePicker
            {...field}
            name={props.name}
            placeholderText={props.placeholder}
            minDate={props.minDate}
            autoComplete={props.autoComplete ?? 'off'}
            selected={value}
            onChange={(date) => handleSetValue(date)}
            showTimeSelect={"showTimeSelect" in props ? props.showTimeSelect : false}
        />
    )
}

export default DateInput;
