import {InputProps} from "../../../models/Inputs/InputProps";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/store";
import {Field} from "formik";
import DateInput from "../DateInput/DateInput";
import {selectArrowSvg} from "../../../helpers/SvgTheming";
import {Option} from "../../../models/Inputs/Option";
import React, {FormEvent} from "react";
import PhoneInput from "../PhoneInput/PhoneInput";

const InputSwitch = (value: InputProps, formikMeta: any) => {
    const user = useSelector((state: RootState) => state.userSlice.user);

    switch (value.type) {
        case 'text':
        case 'email':
        case 'number':
            if (value.onInput) {
                return (
                    <Field className="input"
                           key={value.name}
                           type={value.type}
                           name={value.name}
                           placeholder={value.placeholder}
                           onInput={value.onInput}
                    />
                )
            }
            return (
                <Field className="input"
                       key={value.name}
                       type={value.type}
                       name={value.name}
                       placeholder={value.placeholder}
                />
            )
        case 'date':
            return (
                <DateInput
                    className={'w-full'}
                    key={value.name}
                    name={value.name}
                    minDate={value.minDate ? new Date(value.minDate) : undefined}
                    placeholder={value.placeholder}
                    showTimeSelect={value.showTimeSelect}
                />
            )
        case 'select':
            if (value.onSelect) {
                return (
                    <Field as="select" className="input select"
                           style={{backgroundImage: `url('${selectArrowSvg(user?.color)}')`}}
                           key={value.name}
                           name={value.name}
                           onChange={(e: FormEvent<HTMLSelectElement>) => {
                               value?.onSelect && value.onSelect(e);
                               formikMeta?.setFieldValue(value.name, +(e.target as HTMLSelectElement).value);
                           }}
                    >
                        {
                            value.options?.map((option: Option) => (
                                <option key={option.name} value={option.value}>{option.name}</option>
                            ))
                        }
                    </Field>
                )
            }
            return (
                <Field as="select" className="input select"
                       style={{backgroundImage: `url('${selectArrowSvg(user?.color)}')`}}
                       key={value.name}
                       name={value.name}
                >
                    {
                        value.options?.map((option: Option) => (
                            <option key={option.name} value={option.value}>{option.name}</option>
                        ))
                    }
                </Field>
            )
        case 'phone':
            return (
                <PhoneInput
                    key={value.name}
                    className="input"
                    placeholder={value.placeholder}
                    name={value.name}
                />
            )
    }
}

export default InputSwitch
